import React from 'react';
import {Link} from 'react-router-dom';

import {Row, Col, Card, Badge, Button, Spinner, Table} from 'react-bootstrap';
import {GearFill, Speedometer2, ArrowCounterclockwise, Recycle, Activity} from 'react-bootstrap-icons';

import moment from 'moment';

import {lifecycleStates} from '../_helpers/lifecycleStates';

const EdgeCard = (props) => {
  // only id from the name (first digits before an `-`)
  // const id = props.edgeData.name.split('-')[0] + '-' + props.edgeData.name.split('-')[1];
  const id = props.edgeData.name;


  return (
    // rounded corners
    <Card className="edge-card" key={props.edgeData.id} border={props.edgeData.activated && "success"}>
      <Card.Body className="text-center">
        <h5>{id} <Badge pill
                        bg={props.edgeData.status?.balena?.isOnline === true ? "success" : "danger"}> </Badge>
        </h5>
        <Table>
          <tbody>
          <tr>
            <th className="text-start">Adresse</th>
            <td className="text-end">{props.edgeData.address}</td>
          </tr>
          <tr>
            <th className="text-start">LAN IP</th>
            <td className="text-end">{props.edgeData.status?.balena?.localIp?.toString()}</td>
          </tr>
          {props.edgeData.lifecycle && <tr>
            <th className="text-start">Lifecycle <Recycle/></th>
            <td className="text-end"><Badge
              bg={lifecycleStates[props.edgeData.lifecycle.status]}>{props.edgeData.lifecycle.status}</Badge></td>
          </tr>}
          </tbody>
        </Table>
        <small>lastUpdated: {props.edgeData.status?.balena?.lastUpdate ? moment(props.edgeData.status?.balena?.lastUpdate?.toDate()).format('DD.MM.YYYY HH:mm:ss') : <>N/A</>}
          {props.edgeData.balenaUpdatePending ? <Spinner animation="border" size="sm"/> :
            <Button size="sm" variant="link"
                    onClick={() => props.updateBalenaStatus(props.edgeData.id)}><ArrowCounterclockwise/></Button>}
        </small>
        <Row>
          <Col>
            {props.edgeData.isAdmin &&
              <Link to={"/edges/" + props.edgeData.id + "/configuration"}>
                <Button variant="link"><GearFill/> Konfiguration</Button>
              </Link>}
          </Col>
          {props.edgeData.meterReport &&
            <Col>
              <Link to={"/edges/" + props.edgeData.id + "/meterreport"}>
                <Button variant="link"><Speedometer2/> Zählerauswertung</Button></Link>
            </Col>
          }
          <Col>
            <Link to={"/edges/" + props.edgeData.id + "/statistics"}><Button
              variant="link"><Activity/> Statistiken</Button></Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default EdgeCard;
