import React, {useState, useEffect} from 'react';

import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";
import {useAuthState} from '../Firebase';
import {Spinner, Button, Form} from 'react-bootstrap';
import {Route} from 'react-router-dom';
import {Envelope, HouseLock, Key, Lock, Mailbox, Mailbox2, Person, Speedometer2} from "react-bootstrap-icons";


const provider = new OAuthProvider('microsoft.com');

const AuthenticatedRoute = ({component: C, ...props}) => {

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [error, setError] = useState();
  const [passwordSent, setPasswordSent] = useState();

  const authState = useAuthState();
  const auth = getAuth();

  const signInWithM365 = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
      })
      .catch((error) => {
        // Handle error.
        console.log(error);
      });
    setLoading(false);
  }

  const signInWithEmail = (event) => {
    event.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        // ...
      })
      .catch((error) => {
        setError(error)
      });
    setLoading(false);
  }

  const passwordReset = () => {
    setLoading(false);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setPasswordSent("Link für Password Reset wurde per E-Mail gesendet!")
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        setError(error)
        // ..
      });
    setLoading(false);
  }

  return (
    <Route
      {...props}
      render={routeProps =>
        authState.user === undefined ? <Spinner animation="border"/> :
          (authState.user === null ?
            <>
              <div className="Auth-form-container">
                <form className="Auth-form" onSubmit={signInWithEmail}>
                  <div className="Auth-form-content">
                    <h1>Anmelden</h1>
                    <p>mit E-Mail-Adresse und Password</p>
                    <br/>
                    <div className="form-group mt-3">
                      <p><Envelope/> E-Mail</p>
                      <input
                        type="email"
                        className="form-control mt-1"
                        placeholder="Geben Sie Ihre E-Mail-Adresse ein"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <p><Lock/> Passwort</p>
                      <input
                        type="password"
                        className="form-control mt-1"
                        placeholder="Geben Sie Ihr Passwort ein"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>
                    <p className="forgot-password">
                      <a onClick={passwordReset}>Passwort vergessen?</a>
                    </p>
                    <div className="d-grid gap-2 mt-3">
                      <button type="submit" className="btn btn-primary">
                        Anmelden
                      </button>
                    </div>
                  </div>
                  {error && <p style={{"color": "red"}}><br/>{error.code} {error.message}</p>}
                  {passwordSent && <p><br/>{passwordSent}</p>}
                  <br/>
                  <p>oder</p>
                  <Button variant="link" onClick={signInWithM365}><img
                    src="/ms-symbollockup_signin_dark.svg"></img></Button>
                  <p style={{marginTop: "200px"}} className="kein-konto">Kein Konto? <br/> <a href="/signup">Jetzt registrieren</a></p>
                </form>
              </div>
              <br/>
            </>


            :
            <C {...props} />)}
    />
  )
}

export {AuthenticatedRoute};