import React, { useState, useEffect } from 'react';


const Test = (props) => {
  useEffect(() => {
    
  }, [])

  return (
    <h2>This is an unprotected Test Route</h2>
  )
}

export default Test;