import React, {useEffect, useState} from 'react';

import {Alert, Button, ButtonGroup, Col, Form, Row} from 'react-bootstrap';
import {Collection, Map, PlusCircle} from 'react-bootstrap-icons';

import {doc, getDoc, collection, getDocs, onSnapshot, setDoc} from 'firebase/firestore';
import {getAuth} from "firebase/auth";

import {db} from '../Firebase';
import EdgeCard from './edgeCard.js';
import EdgeMap from './edgeMap.js';
import {EdgeAdd} from "./edgeAdd";


const EdgesOverview = (props) => {

  const [searchValue, setSearchValue] = useState("");
  // const [edgesClaims, setEdgesClaims] = useState();
  // const [rolesClaims, setRolesClaims] = useState([]);
  const [ claims, setClaims ] = useState({});

  const [edgeData, setEdgeData] = useState([]);

  const [viewMode, setViewMode] = useState("card");

  const [showAddEdge, setShowAddEdge] = useState(false);

  const getEdgeToken = async () => {
    const idTokenResult = await getAuth().currentUser.getIdTokenResult();
    // setEdgesClaims(idTokenResult?.claims?.edges);
    // setRolesClaims(idTokenResult?.claims?.roles);
    setClaims(idTokenResult?.claims);
  }

  const getEdgeData = async () => {
    if (claims?.roles?.includes('globalAdmin')) {
      // it's a global admin 
      const querySnapshot = await getDocs(collection(db, 'edges'));
      let eData = [];
      querySnapshot.forEach((doc) => {
        eData.push({...doc.data(), id: doc.id, isAdmin: true, visible: true});
      });
      eData = eData.sort((a, b) => a.id - b.id)
      setEdgeData(eData);

    } else if (claims?.edges && Array.isArray(claims.edges.admin) && Array.isArray(claims.edges.viewer)) {
      let eData = [];
      let adminEdgeIds = [];
      let viewerEdgeIds = [];
      claims.edges.admin.forEach((o) => {
        adminEdgeIds.push(o)
      });
      claims.edges.viewer.forEach((o) => {
        viewerEdgeIds.push(o)
      });

      await Promise.all(adminEdgeIds.map(async (e) => {
        const balenaUpdateSnap = await getDoc(doc(db, 'balenaUpdate', e));
        const docSnap = await getDoc(doc(db, 'edges', e));
        if (docSnap.exists()) {
          eData.push({
            ...docSnap.data(),
            id: docSnap.id,
            isAdmin: true,
            visible: true,
            balenaUpdatePending: balenaUpdateSnap.exists()
          });
        }
      }))

      await Promise.all(viewerEdgeIds.map(async (e) => {
        const balenaUpdateSnap = await getDoc(doc(db, 'balenaUpdate', e));
        const docSnap = await getDoc(doc(db, 'edges', e));
        if (docSnap.exists()) {
          eData.push({
            ...docSnap.data(),
            id: docSnap.id,
            isAdmin: false,
            visible: true,
            balenaUpdatePending: balenaUpdateSnap.exists()
          });
        }
      }))

      eData = eData.sort((a, b) => a.id - b.id)
      setEdgeData(eData);
    };
  }

  const updateBalenaStatus = async (id) => {
    let edges = edgeData;
    let index = edgeData.findIndex(e => e.id == id);
    await setDoc(doc(db, "balenaUpdate", id), {tStamp: new Date()});
    edges[index].balenaUpdatePending = true;
    setEdgeData([...edges]);

    // wait for update
    const unsubscribe = onSnapshot(doc(db, "balenaUpdate", id), async (d) => {
      if (!d.exists()) {
        const updatedDoc = await getDoc(doc(db, 'edges', id));
        edges[index] = {...edges[index], ...updatedDoc.data(), balenaUpdatePending: false};
        setEdgeData([...edges]);
        unsubscribe();
      }

    });
  }

  const searchValueChanged = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    const re = new RegExp(e.target.value, "i")
    let edges = [...edgeData];
    edges.forEach((element, index) => {
      if (element.name?.match(re) || element.address?.match(re) || element.lifecycle?.status?.match(re)) {
        edges[index].visible = true;
      } else {
        edges[index].visible = false;
      }
    })

    setEdgeData(edges);
  }

  useEffect(() => {
    getEdgeToken();
  }, [])

  useEffect(() => {
    getEdgeData();
  }, [claims]);

  return (
    edgeData.length ?
      <>
        <EdgeAdd show={showAddEdge} onHide={() => setShowAddEdge(false)}/>
        <Row>
          <Col className="d-flex">
            <Form.Control type="text" placeholder="Suche..." value={searchValue} onChange={searchValueChanged}/>
            <ButtonGroup className="float-start ms-2">
              <Button variant={viewMode === "card" ? 'secondary' : 'outline-secondary'}
                      onClick={() => setViewMode('card')}><Collection/></Button>
              <Button variant={viewMode === "map" ? 'secondary' : 'outline-secondary'}
                      onClick={() => setViewMode('map')}><Map/></Button>
            </ButtonGroup>
          </Col>
          <Col>
            {/* <ButtonGroup className="float-end ms-2">
              <Button variant={'outline-secondary'}
                      onClick={() => setShowAddEdge(true)}><PlusCircle/></Button>
            </ButtonGroup> */}
          </Col>
        </Row>
        {viewMode === 'map' ?
          <Row>
            <EdgeMap edgeData={edgeData}/>
          </Row>
          :
          <Row xs={1} md={2}>
            {edgeData?.map(e =>
              e.visible && <Col key={e.id} className="p-2"><EdgeCard key={JSON.stringify(e)} edgeData={e}
                                                                     updateBalenaStatus={(id) => updateBalenaStatus(id)}/></Col>
            )}
          </Row>
        }
      </>

      : <Alert variant="success">
        Willkommen im Swisscharge LAMA Portal!
        <br/><br/>
        Ihnen wurden noch keine LAMAs zugewiesen. Kontaktieren Sie uns unter <a
        href="mailto:scc@lehmann.ch">info@swisscharge.ch</a>
      </Alert>
  )
}

export default EdgesOverview;