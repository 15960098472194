import React from 'react';

import EvCharger from "../evCharger";
import Icon from "../../icon";
import Circuit from "../circuit";
import {NodeTypes} from "../../../constants/nodeTypes";

// Custom square node component
const RenderForeignObjectNode = ({nodeDatum, foreignObjectProps, addNode}) => {

  const nodeClicked = () => {
    console.log("the node has been clicked")
    console.log(nodeDatum)
  }

  switch (nodeDatum.type) {
    case NodeTypes.ADDNODE:
      return (
       <g onClick={() => addNode(nodeDatum)}>
         <circle transform="translate(20, 0)" r={25} stroke="none" fill="transparent"></circle>
         <g transform="translate(-5,-25)">
           <Icon name="add" color={"#0d6efd"} size={50}/>
         </g>
       </g>
      );
    case NodeTypes.EVCHARGER:
      return (
       <g>
         <circle r={10} onClick={nodeClicked} fill="#0d6efd"></circle>
         <foreignObject transform="translate(0, 20)" {...foreignObjectProps} >
           <EvCharger nodeDatum={nodeDatum}/>
         </foreignObject>
       </g>
      );
    case NodeTypes.CIRCUIT:
      return (
       <g>
         <circle r={15} onClick={nodeClicked} fill="white"></circle>
         <foreignObject transform="translate(0, 20)" {...foreignObjectProps} >
           <Circuit nodeDatum={nodeDatum}/>
         </foreignObject>
       </g>
      );
  }
}
export default RenderForeignObjectNode;