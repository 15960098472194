import {Button} from "react-bootstrap";
import {useData} from "../../../context/dataContext";

export const NodeDropdown = ({nodeData, isHidden}) => {
  const {setEditNodeModalOpen, setSelectedNode, treeData, deleteNode, setIsEditMode, setCircuitFormData} = useData();

  if (isHidden) {
    return null;
  }

  const openModal = () => {
    setSelectedNode(nodeData.name);
    setEditNodeModalOpen(nodeData.type);
    if (nodeData.type === 'circuit') {
      setCircuitFormData(nodeData.attributes);
    }
    setIsEditMode(true);
  };

  const deleteTreeNode = () => {
    console.log("deleteTreeNode: ", nodeData.name)
    deleteNode(treeData, nodeData.name);
  };

  return (
   <div className="btn-group-vertical z-3"
        style={{
          position: 'absolute',
          top: '100%',
          left: '-400%', // Adjust as needed
          zIndex: 10
        }}>
     <button onClick={openModal} type='button' className="btn btn-primary">Bearbeiten</button>
     <button onClick={deleteTreeNode} type='button' className="btn btn-danger">Loeschen</button>
   </div>
  )
}

export default NodeDropdown;