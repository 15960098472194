import React, {createContext, useContext, useState, useEffect} from 'react';
import _, {isArray, set} from 'lodash';
import {NodeTypes} from "../constants/nodeTypes";
import {sccjsonTemplate, circuitTemplate} from '../constants/JsonTemplates';
import tree from "../tree";

const DataContext = createContext();

const initialTree = {
  name: '0',
  type: NodeTypes.ADDNODE,
};

export const DataProvider = ({children}) => {
  const [currentJsonText, setCurrentJsonText] = useState(JSON.stringify(sccjsonTemplate, null, 2));

  // TODO load initial tree from currentJsonText
  const [treeData, setTreeData] = useState(initialTree);

  const [selectedNode, setSelectedNode] = useState('0');
  const [editNodeModalOpen, setEditNodeModalOpen] = useState(false);

  const [advancedMode, setAdvancedMode] = useState(false);

  const [circuitFormData, setCircuitFormData] = useState(_.cloneDeep(circuitTemplate));

  const [evChargerFormData, setEvChargerFormData] = useState({});

  const [isEditMode, setIsEditMode] = useState(false);

  const getDeviceNames = () => {
    let deviceNames = [];
    const {devices} = JSON.parse(currentJsonText);
    for (const deviceType in devices) {
      if (!isArray(devices[deviceType])) {
        deviceNames.push({deviceName: devices[deviceType].name, deviceType});
      } else if (devices[deviceType].length > 0) {
        for (let i = 0; i < devices[deviceType].length; i++) {
          deviceNames.push({
            deviceName: devices[deviceType][i].name,
            deviceType,
            deviceSubType: devices[deviceType][i].deviceType
          });
        }
      }
    }
    return deviceNames;
  }

  const deleteNode = (treeData, nodeId) => {
    console.log(`nodeId in deleteNode: ${nodeId}`);

    const findAndDeleteNode = (node, nodeId, parent = null) => {
      if (node.name === nodeId) {
        if (parent) {
          // Remove the node from its parent's children
          console.log('parent found')
          parent.children = parent.children.filter(child => child.name !== nodeId);
        } else {
          // If no parent, it means it's the root node
          node.children = [];
          node.attributes = {};
          node.type = NodeTypes.ADDNODE;
        }
        return true; // Node found and deleted
      }

      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          if (findAndDeleteNode(node.children[i], nodeId, node)) {
            return true; // Node found and deleted in a subtree
          }
        }
      }
      return false; // Node not found in this path
    }

    let newTreeData = _.cloneDeep(treeData);
    findAndDeleteNode(newTreeData, nodeId);
    updateTreeData(newTreeData);
  }
  // this function searches for the name of the add node which has been clicked (e.g. "0,1")
  const updateNestedObject = (obj, conditionFn, updateFn, newChild = null, parent = null) => {
    // Check if the current object meets the condition to be updated
    if (conditionFn(obj)) {
      let nodeTypeBefore = obj.type;
      console.log(`this object: ${JSON.stringify(obj, null, 2)}`)
      console.log(`parent object: ${JSON.stringify(parent, null, 2)}`)
      updateFn(obj);
      if (parent && newChild && nodeTypeBefore === NodeTypes.ADDNODE) {
        parent.push(newChild);
      }
      return true;
    }
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          if (updateNestedObject(obj[key], conditionFn, updateFn, newChild, obj)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  // create a new child node "addnode" with the name of the selected node + 1
  const getNewChild = () => {
    let parts = selectedNode.split(',');
    parts[parts.length - 1] = (parseInt(parts[parts.length - 1]) + 1).toString();
    return {
      name: `${parts.join(',')}`,
      type: NodeTypes.ADDNODE
    }
  }

  // save the circuit data to the selected node
  const saveCircuit = () => {
    let newTreeData = _.cloneDeep(treeData);

    const conditionFn = (obj) => obj.name === selectedNode;
    const updateFn = (obj) => {
      const initialType = obj.type;
      obj.type = NodeTypes.CIRCUIT;
      obj.attributes = circuitFormData;

      if (initialType === NodeTypes.CIRCUIT) {
        return;
      }
      obj.children = [
        {
          name: `${selectedNode},0`,
          type: NodeTypes.ADDNODE
        }
      ];
    }

    updateNestedObject(newTreeData, conditionFn, updateFn, getNewChild());
    updateTreeData(newTreeData);

    setCircuitFormData(circuitTemplate);
    setEditNodeModalOpen(false);
  }

  // Here it looks for the actual node data by its name. Not sure if this was already implemented tho
  function findNodeParent(node, name) {
    console.log("looking of parent for: ", name, " in node: ", node.name)
    if (node.name === name) {
      console.log("no parent here for: ", name, " in node: ", node.name)
      return node;
    }

    if (node.children && node.children.length) {
      for (let child of node.children) {
        let result = findNodeParent(child, name);
        if (result) {
          console.log("parent found")
          return result;
        }
      }
    }
    return null;
  }

  // save the evCharger data to the selected node
  const saveEvCharger = (evChargerType) => {
    let newTreeData = _.cloneDeep(treeData);
    const conditionFn = (obj) => obj.name === selectedNode;
    const updateFn = (obj) => {
      obj.type = NodeTypes.EVCHARGER;
      obj.attributes = {};
      obj.attributes[evChargerType] = evChargerFormData;
    }

    updateNestedObject(newTreeData, conditionFn, updateFn, getNewChild());


    updateTreeData(newTreeData);

    setEditNodeModalOpen(false);
  }

  // convert the json circuit to the tree view
  const convertCircuitToTree = (circuit, count) => {
    let treeObj = {}
    treeObj.name = count;
    treeObj.type = NodeTypes.CIRCUIT;
    const {circuits, evChargers, ...attributes} = circuit;
    treeObj.attributes = attributes;

    treeObj.children = [];
    let index = 0;
    if (circuits?.length > 0) {
      for (let i = 0; i < circuits.length; i++) {
        treeObj.children.push(convertCircuitToTree(circuits[i], `${count},${index}`));
        index++;
      }
    }

    if (evChargers?.length > 0) {
      for (let i = 0; i < evChargers.length; i++) {
        treeObj.children.push({
          name: `${count},${index}`,
          type: NodeTypes.EVCHARGER,
          attributes: evChargers[i],
        });
        index++;
      }
    }

    // add node
    treeObj.children.push({
      name: `${count},${index}`,
      type: NodeTypes.ADDNODE
    });

    return treeObj;
  }

  const updateCurrentJsonText = (newJsonText) => {
    setCurrentJsonText(newJsonText);
    let newJson = {}
    try {
      newJson = JSON.parse(newJsonText);
    } catch (err) {
      newJson = {err: "invalid JSON"};
    }
    let newTreeData = {};
    if (newJson.circuits?.length > 0) {
      newTreeData = convertCircuitToTree(newJson.circuits[0], "0");
    } else {
      newTreeData = initialTree;
    }
    setTreeData(newTreeData);

  }


  // convert the tree view to the json circuit
  const convertTreeToCircuit = (treeObj) => {
    const {name, type, attributes, children} = treeObj;
    let obj = {};
    if (type === NodeTypes.CIRCUIT) {
      obj = {
        ...attributes,
        circuits: [],
        evChargers: [],
      };
      if (children?.length > 0) {
        for (let i = 0; i < children.length; i++) {
          if (children[i].type === NodeTypes.CIRCUIT) {
            obj.circuits.push(convertTreeToCircuit(children[i]));
          } else if (children[i].type === NodeTypes.EVCHARGER) {
            obj.evChargers.push(children[i].attributes);
          }
        }
      }

    } else if (type === NodeTypes.EVCHARGER) {
      obj = {
        ...attributes,
      };
    }

    return obj;
  };

  // update the tree view and the json circuit
  const updateTreeData = (newTreeData) => {
    setTreeData(newTreeData);

    let newJson = {
      ...JSON.parse(currentJsonText),
      circuits: [],
    };

    newJson.circuits.push(convertTreeToCircuit(newTreeData));
    setCurrentJsonText(JSON.stringify(newJson, null, 2));
  }

  // useEffect(() => {
  //   console.log(`selectedNode: ${selectedNode}`);
  // }, [selectedNode]);

  const validateCurrentJson = () => {
    try {
      JSON.parse(currentJsonText);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    console.log(`new Tree Data: ${JSON.stringify(treeData, null, 2)}`);
  }, [treeData]);

  return (
    <DataContext.Provider value={{
      // states
      currentJsonText,
      //setCurrentJsonText,
      treeData,
      isEditMode,
      setIsEditMode,
      //setTreeData,
      circuitFormData,
      setCircuitFormData,
      evChargerFormData,
      setEvChargerFormData,
      selectedNode,
      setSelectedNode,
      editNodeModalOpen,
      setEditNodeModalOpen,
      advancedMode,
      setAdvancedMode,

      // functions
      updateCurrentJsonText,
      updateTreeData,
      saveCircuit,
      findNodeByName: findNodeParent,
      deleteNode,
      saveEvCharger,
      getDeviceNames,
      validateCurrentJson,
    }}>
      {children}
    </DataContext.Provider>
  );
}

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
}