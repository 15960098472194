import React, { useState, useEffect } from 'react';

import { Form, Button, Row, Col, Badge } from 'react-bootstrap';

import { doc, getDoc, setDoc, addDoc, collection, onSnapshot, query, orderBy, limit } from 'firebase/firestore';

import { getAuth } from "firebase/auth";

import moment from 'moment';

import {db} from '../Firebase';

import { lifecycleStates } from '../_helpers/lifecycleStates';

// const lifecycleStates = [
//   ['ordered', 'info'],
//   ['shipped', 'secondary'],
//   ['activated', 'success'],
//   ['disabled', 'dark'],
//   ['waiting', 'danger'],
// ]



const Lifecycle = (props) => {
  const [ conf, setConf ] = useState("");
  const [ editMode, setEditMode ] = useState(false);

  const [ selectedLifecycle, setSelectedLifecycle ] = useState('waiting');
  const [ comment, setComment ] = useState('');

  const [ lifecycleHistory, setLifecycleHistory ] = useState([]);

  const setLifecycle = async() => {
    await addDoc(collection(db, "edges", props.eid, "lifecycle"), {status: selectedLifecycle, comment, created: new Date()});
  }

  useEffect(() => {
    // const unsubscribe = onSnapshot(collection(db, "cities"), () => {
    //   // Respond to data
    //   // ...
    // });

    if(props.eid) {
      const q = query(collection(db, "edges", props.eid, 'lifecycle'), orderBy("created", "desc"), limit(5));
      const unsubscribe = onSnapshot(q, (snap) => {
        let history = []
        snap.forEach(s => {
          history.push(s.data());
        })
        setLifecycleHistory(history);
      });
      return () => unsubscribe();
    }

  }, [])


  return (
    <>
      <Row >
        <Col>
          <Form.Select value={selectedLifecycle} onChange={(e) => setSelectedLifecycle(e.target.value)} >
            {Object.keys(lifecycleStates).map((key, index) => <option key={key} value={key}>{key}</option>)}
          </Form.Select>
        </Col>
        <Col>
          <Form.Control type="text" placeholder="Kommentar" value={comment} onChange={(e) => setComment(e.target.value)}/>
        </Col>
        <Col>
          <Button onClick={setLifecycle}>Speichern</Button>
        </Col>
      </Row>
      <hr />
      {lifecycleHistory.map(l =>
        <Row key={l.created}>
          <Col>{moment(l.created.toDate()).format('DD.MM.YYYY HH:mm:ss')}</Col>
          <Col><Badge bg={lifecycleStates[l.status]}>{l.status}</Badge></Col>
          <Col><p>{l.comment}</p></Col>
        </Row>  
      )}
    </>
  )
}

export default Lifecycle;