import React, { useState, useEffect } from 'react';

import { Spinner, Card, Form, Table, Button, ButtonGroup } from 'react-bootstrap';
import { Pencil, X, Check } from 'react-bootstrap-icons';

import { doc, getDoc, setDoc } from 'firebase/firestore';

import { getAuth } from "firebase/auth";

import {db} from '../Firebase';

const ConfigText = (props) => {
  const [ conf, setConf ] = useState("");
  const [ editMode, setEditMode ] = useState(false);

  const getConfigText = async() => {
    const docRef = doc(db, "edges", props.eid, "configurations", "configText");
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()) {
      const data = docSnap.data();
      setConf(data.value)
    } else {
      setConf("");
    }
  }

  const saveConfigText = async() => {
    const docRef = doc(db, "edges", props.eid, "configurations", "configText");
    await setDoc(docRef, {value: conf}, {merge: true});
    setEditMode(false); 
  }

  useEffect(() => {
    getConfigText();
  }, [])

  // const onSwitchClick = async() => {
  //   if(deviceURL) {
  //     await deleteDoc(doc(db, "balenaDeviceURLs", props.eid));
  //   } else {
  //     const uid = getAuth().currentUser.uid;
  //     await setDoc(doc(db, "balenaDeviceURLs", props.eid), {createdAt: new Date(), createdBy: uid});
  //   }
  // }

  const onTextChange = (e) => {
    setConf(e.target.value);
  }

  const onTextAreaClick = (e) => {
    e.ctrlKey && setEditMode(true)
  }

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Summary - Config Text</Form.Label>
          <Form.Control as="textarea" rows={12} value={conf} plaintext={!editMode} readOnly={!editMode} onChange={onTextChange} onClick={onTextAreaClick} />
        </Form.Group>
      </Form>
      <div className="d-flex flex-row-reverse">
      {editMode ?
        <ButtonGroup>
          <Button variant="outline-primary" onClick={() => {setEditMode(false); getConfigText()}}><X /></Button>
          <Button variant="outline-primary" onClick={() => saveConfigText()}><Check /></Button>
        </ButtonGroup> 
        :
        <Button variant="outline-primary" onClick={() => setEditMode(true)}><Pencil /></Button>
      }
      </div>
    </>
  )
}

export default ConfigText;