export const deviceTemplates = {
  SmartPi: {
    name: "SmartPi"
  },
  RpiGPIO: {
    name: "Lastabwurf_EW",
    direction: "in",
    gpio: 23
  },
  ModbusRTUMeter: {
    deviceType: "PRO380-Mod",
    name: "",
    modbusId: 1,
    usbDevice: "/dev/ttyUSB0"
  },
  ModbusTCPMeter: {
    deviceType: "SiemensPAC2200",
    name: "",
    modbusId: 1,
    ip: ""
  },
  DSMR5: {
    name: "SAK Smart Meter",
    usbDevice: "/dev/ttyUSB0"
  },
  ShellyPro3EM: {
    name: "Shelly Pro 3EM",
    apiUrl: "https://",
    authKey: "",
    id: "",
  }
}

export const sccjsonTemplate = {
  "circuits": [],
  "devices": {
    "RpiGPIO": [
      {
        "name": "Lastabwurf_EW",
        "direction": "in",
        "gpio": 23
      }
    ],
    "SmartPi": {
      "name": "SmartPi"
    }
  }
}

export const circuitTemplate = {
  measurement: {
    type: 'device',  // sum, device
    deviceName: '',
  },
  controlSettings: {
    decissionInterval: 60,
    lowerLimit: 80,
    upperLimit: 100,
  },
  actions: [],
  evChargers: [],
  circuitName: '',
}