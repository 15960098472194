import React, {useEffect, useState} from 'react';

import {Button, Card, Col, Modal, Row} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import {ArrowClockwise} from "react-bootstrap-icons";
import {Tooltip} from "recharts";

const CustomRangeStat = ({onShow, updateAbsoluteRange}) => {
  useEffect(() => {
    onShow(handleShow);
  }, [onShow]);

  const [loading, setLoading] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState({start: undefined, end: undefined});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const generateReport = async () => {
    setLoading(true);


    let start = (new Date(selectedPeriod.start)).toISOString();
    let end = new Date(selectedPeriod.end);
    end = end.toISOString();

    updateAbsoluteRange(start, end);

    handleClose();
  }

  const startMinDate = (endDate) => {
    // min date must not be older than 1 year or if end date is set, not more than 30 days from end date
    if (endDate !== undefined) {
      return new Date(selectedPeriod.end).setDate(new Date(selectedPeriod.end).getDate() - 30)
    }
    return new Date().setFullYear(new Date().getFullYear() - 1);
  };

  const startMaxDate = (endDate) => {
    // Max date must not be in the future or if end date set not more than end date
    if (endDate !== undefined) {
      return new Date(selectedPeriod.end)
    }
    return new Date();
  }

  const endMaxDate = (startDate) => {
    // max date must not be in the future or more than 30 days from start date
    if (startDate !== undefined || new Date(selectedPeriod.start).setDate(new Date(selectedPeriod.start).getDate() + 30) > new Date()) {
      return new Date(selectedPeriod.start).setDate(new Date(selectedPeriod.start).getDate() + 30)
    }
    return new Date();
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Card>
          <h5 className="m-3">Benutzerdefinierter Bereich</h5>
          <Card.Body>
            <Row className="d-flex align-items-end">
              <Col>Start: <DatePicker showTimeSelect selected={selectedPeriod.start}
                                           maxDate={startMaxDate(selectedPeriod.end)}
                                           minDate={startMinDate(selectedPeriod.end)}
                                           onChange={(date) => setSelectedPeriod({
                                             ...selectedPeriod,
                                             start: date
                                           })}
                                           dateFormat="dd.MM.yyyy HH:mm"
              /></Col>
              <Col>Ende: <DatePicker showTimeSelect selected={selectedPeriod.end}
                                         maxDate={endMaxDate(selectedPeriod.start)}
                                         disabled={!selectedPeriod.start}
                                         minDate={new Date(selectedPeriod.start)}
                                         onChange={(date) => setSelectedPeriod({...selectedPeriod, end: date})}
                                         dateFormat="dd.MM.yyyy HH:mm"
              /></Col>
            </Row>
          </Card.Body>
          <div className="m-3 text-start">
            <p style={{fontSize: 14}} className="mb-0 alert alert-warning"> 1. Bereich maximal 30 Tage.
              <br/>
              2. Startdatum kann nicht älter als 1 Jahr sein.</p>
          </div>
        </Card>
        <Modal.Footer>
          <Col><Button variant='primary' disabled={!selectedPeriod.start || !selectedPeriod.end}
                       className='mb-0' onClick={function (){
                         generateReport();
                       }}>Anwenden</Button></Col>
          <Button variant='outline-danger' className='float-end m-2' onClick={() => {
            setSelectedPeriod({start: undefined, end: undefined});
          }}><ArrowClockwise/></Button>
          <Button variant='outline-secondary' onClick={() => {
            setSelectedPeriod({start: undefined, end: undefined});
            handleClose();
          }}>Abbrechen</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CustomRangeStat;