export const ChargerTypes = {
  EASEE: "easeeCircuit",
  ZAPTEC: "zaptecInstallation",
  ALFEN: "alfenSCN",
  ALPITRONIC: "alpitronicHypercharger",
  ALFEN_SOCKET: "alfenSocket",
};

export const StepTypes = {
  RATIO: "ratio",
  ABSOLUTE: "absolute"
};

export const evChargerTemplate = {
  [ChargerTypes.ALFEN]: {
    ip: "",
    minCurrent: 20,
    decrease: {
      type: "ratio",
      value: 0.8,
    },
    increase: {
      type: "absolut",
      value: 16,
    },
  },
  [ChargerTypes.EASEE]: {
    siteId: "",
    circuitId: "",
    minCurrent: 20,
    credentials: {
      username: "",
      password: "",
    },
    decrease: {
      type: "ratio",
      value: 0.8,
    },
    increase: {
      type: "absolut",
      value: 16,
    },
  },
  [ChargerTypes.ZAPTEC]: {
    installationId: "",
    minCurrent: 32,
    credentials: {
      username: "",
      password: "",
    },
    decrease: {
      type: "ratio",
      value: 0.8,
    },
    increase: {
      type: "absolut",
      value: 16,
    },
  },
  [ChargerTypes.ALPITRONIC]: {
    ip: "",
    minPowerW: 50,
    decrease: {
      type: "ratio",
      value: 0.8,
    },
    increase: {
      type: "absolut",
      value: 16,
    },
  },
  [ChargerTypes.ALFEN_SOCKET]: {
    ip: "",
    port: 502,
    socketId: 1,
    minCurrent: 16,
    decrease: {
      type: "absolut",
      value: 4
    },
    increase: {
      type: "absolut",
      value: 4
    }
  },
}