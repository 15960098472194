import React, {useState, useEffect} from 'react';
import {signOut, getAuth} from "firebase/auth";
import {useAuthState} from '../Firebase';

import {Navbar, Nav, Container, Button} from 'react-bootstrap';
import {useParams} from "react-router-dom";

const Navigation = (props) => {
  useEffect(() => {

  }, [])

  const authState = useAuthState();
  const auth = getAuth();
  const {eid} = useParams();

  return (
    <Navbar className="navbar-custom" bg="light" variant="light">
      <Container>
        {/* <Navbar.Brand href="/">Smart Charge Controller</Navbar.Brand> */}
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
              alt=""
              src="/img/lama-logo.png"
              width="40"
              className="d-inline-block align-top"
            />{' '}
            Swisscharge LAMA
        </Navbar.Brand>
        {/* <div className="d-flex justify-content-center w-100">
          <img src="/img/Swisscharge-Logo-Positiv-RGB.png" alt="Swisscharge" width="140" className="swisscharge-logo" />
        </div> */}
        {authState.user !== undefined && authState.user !== null && (
          <Nav>
            <Nav.Link href="#" onClick={() => {
              signOut(auth)
            }}>Logout</Nav.Link>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
}

export default Navigation;