import React, {useEffect, useRef, useState} from 'react';
import Tree from 'react-d3-tree';
import RenderForeignObjectNode from '../components/nodes/renderForeignObject';
import {useCenteredTree} from '../utils/helpers';

import AddNodeModal from '../components/modals';
import {NodeTypes} from "../constants/nodeTypes";

import {DataProvider, useData} from '../context/dataContext';
import {Alert} from "react-bootstrap";

const regularStyles = {
  width: "100%",
  height: "70vh",
  position: "relative",
};

const fullscreenStyles = {
  width: "100%",
  height: "100%",
  backgroundColor: "#fff",
};


const initialTree = {
  name: '0',
  type: NodeTypes.ADDNODE,
};


// Custom node rendering function to create square nodes
const TreeView = () => {
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = {x: 250, y: 400};
  const foreignObjectProps = {width: nodeSize.x, height: nodeSize.y, x: 0};
  const [isFullscreen, setIsFullscreen] = useState(false);
  const treeContainerRef = useRef(null);

  const {treeData, setTreeData} = useData();
  const {selectedNode, setSelectedNode} = useData();

  const {setIsEditMode} = useData();
  const {setEditNodeModalOpen} = useData();

  const {validateCurrentJson} = useData();

  // used in custom node object
  const addNode = (nodeData) => {
    setEditNodeModalOpen(true);
    setIsEditMode(false);
    setSelectedNode(nodeData.name);
  }

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      treeContainerRef.current.requestFullscreen().catch((e) => {
        console.error("Error attempting to enable full-screen mode:", e);
      });
    } else {
      document.exitFullscreen().then(r => console.log(r)).catch((e) => {
        console.error("Error attempting to exit full-screen mode:", e);
      });
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);


  return (
    <>
      <div style={regularStyles} ref={containerRef}>
        <button onClick={toggleFullscreen} style={{
          position: 'absolute',
          right: '20px',
          bottom: '20px',
          zIndex: 1000,
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}>
          <svg fill="#0d6efd" height="30px" width="30px" version="1.1" id="Capa_1" viewBox="0 0 384.97 384.97">
            {/* SVG Content */}
            <g id="SVGRepo_iconCarrier">
              <g>
                <path
                  d="M372.939,216.545c-6.123,0-12.03,5.269-12.03,12.03v132.333H24.061V24.061h132.333c6.388,0,12.03-5.642,12.03-12.03 S162.409,0,156.394,0H24.061C10.767,0,0,10.767,0,24.061v336.848c0,13.293,10.767,24.061,24.061,24.061h336.848 c13.293,0,24.061-10.767,24.061-24.061V228.395C384.97,221.731,380.085,216.545,372.939,216.545z"></path>
                <path
                  d="M372.939,0H252.636c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h91.382L99.635,268.432 c-4.668,4.668-4.668,12.235,0,16.903c4.668,4.668,12.235,4.668,16.891,0L360.909,40.951v91.382c0,6.641,5.39,12.03,12.03,12.03 s12.03-5.39,12.03-12.03V12.03l0,0C384.97,5.558,379.412,0,372.939,0z"></path>
              </g>
            </g>
          </svg>
        </button>
        <div hidden={validateCurrentJson()}>
          <Alert variant="danger">
            Invalid JSON
          </Alert>
        </div>
        <div
          ref={treeContainerRef}
          style={isFullscreen ? fullscreenStyles : regularStyles}
        >
          <Tree
            data={treeData || initialTree}
            translate={translate}
            nodeSize={nodeSize}
            renderCustomNodeElement={(rd3tProps) =>
              RenderForeignObjectNode({...rd3tProps, foreignObjectProps, addNode})
            }
            orientation="horizontal"
            collapsible={false}

            depthFactor={300}
            enableLegacyTransitions={true}
            pathFunc={"step"}
            transitionDuration={300}
            centeringTransitionDuration={300}
          />
        </div>

      </div>
      <AddNodeModal/>
    </>
  );
}

export default TreeView;
