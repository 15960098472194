// react component form 

import {useEffect, useRef, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {newEdge} from "../Firebase";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import './style.css';


export const EdgeAdd = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [edgeName, setEdgeName] = useState();
  const [edgeLocation, setEdgeLocation] = useState();
  const [edgeLatitude, setEdgeLatitude] = useState();
  const [edgeLongitude, setEdgeLongitude] = useState();
  const [edgeType, setEdgeType] = useState("");

  const inputRef = useRef(null);


  const saveEdge = async () => {
    setLoading(true);
    const edgeData = {
      name: edgeName,
      location: edgeLocation,
      // geolocation is of type geopoint
      geolocation: {
        latitude: edgeLatitude,
        longitude: edgeLongitude
      },
    }
    try {
      await newEdge(edgeData);
      setLoading(false);
    } catch (error) {
      console.log(error)
      setError(error);
    }
    // reset form
    props.onHide()
    setEdgeType("");
    setEdgeName("");
    setEdgeLocation("")
  }

  // const {ref, autocompleteRef} = usePlacesWidget({
  //   apiKey: "AIzaSyAMOKqlaiYfsLSwE6GZ__ZUnNacXjClT1g",
  //   onPlaceSelected: (place) => {
  //     setEdgeLocation(place.formatted_address)
  //     console.log(place)
  //   }
  // });

  useEffect(() => {
      if (edgeLocation) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({address: edgeLocation}, (results, status) => {
          if (status === "OK") {
            setEdgeLatitude(results[0].geometry.location.lat());
            setEdgeLongitude(results[0].geometry.location.lng());
            console.log(edgeLatitude, edgeLongitude)
            console.log(edgeLocation)
          }
        })
      }
    }
    , [edgeLocation])

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edge Hinzufügen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/*radio switch*/}
          <Form.Group className="mb-3 d-flex">
            <Form.Label className={"me-3"}>Edge Type:</Form.Label>
            <Form.Check className={"me-3"} type="radio" label="Edge" name="edgeType" id="edge"
                        checked={edgeType === "edge"}
                        onChange={(e) => setEdgeType(e.target.id)}/>
            <Form.Check type="radio" label="ThinEdge" name="edgeType" id="thinedge" checked={edgeType === "thinedge"}
                        onChange={(e) => setEdgeType(e.target.id)}/>
          </Form.Group>
          {edgeType === "edge" &&
            <>
              <Form.Group className="mb-3">
                <Form.Label>Edge Name</Form.Label>
                <Form.Control type="text" onChange={(e) => setEdgeName(e.target.value)}/>
              </Form.Group>
              {/*<Form.Group className="mb-3">*/}
              <Form.Label>Edge Location</Form.Label>
              {/*  <Form.Control*/}
              {/*    type="text"*/}
              {/*    ref={ref}*/}
              {/*    className={"autocomplete"}*/}
              {/*    onChange={(e) => setEdgeLocation(e.target.value)}*/}
              {/*  />*/}
              {/*</Form.Group>*/}
              {/*<input ref={mapsRef} className={"autocomplete"} type="text"/>*/}
              <ReactGoogleAutocomplete className={"autocomplete"}
                                       apiKey="AIzaSyAMOKqlaiYfsLSwE6GZ__ZUnNacXjClT1g"
                                       options={{types: ['address']}}
                                       className={"form-control"}
                                       onPlaceSelected={(place) => {
                                         console.log(`Place: ${JSON.stringify(place)}`);
                                         setEdgeLocation(place.formatted_address)
                                       }}
              />
            </>
          }
          {edgeType === "thinedge" &&
            <>
              <Form.Group className="mb-3">
                <Form.Label>Edge Name</Form.Label>
                <Form.Control type="text" onChange={(e) => setEdgeName(e.target.value)}/>
              </Form.Group>
            </>
          }
          {edgeType === "" &&
            <></>
          }

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          props.onHide();
          setEdgeName("");
          setEdgeLocation("")
          setEdgeType("");
        }}>
          Schliessen
        </Button>
        {/*active only if all fields filled*/}
        {edgeName && edgeLocation ?
          <Button variant="primary" onClick={saveEdge}>
            Speichern
          </Button>
          :
          <Button variant="primary" disabled>
            Speichern
          </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}