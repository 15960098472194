import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import Icon from "../icon";

import CircuitForm from './circuitForm';
import EvChargerForm from './evChargerForm';

import {useData} from '../../context/dataContext';

const MODES = {
  INITIAL: 'initial',
  ADD_CIRCUIT: 'circuit',
  ADD_EV_CHARGER: 'evCharger'
};

const AddNodeModal = (props) => {

  const {editNodeModalOpen, setEditNodeModalOpen, selectedNode} = useData();

  const InitialForm = () => {
    let content;

    if (selectedNode === '0') {
      content = (
       <>
         <Button className="w-100 mb-3" onClick={() => setEditNodeModalOpen(MODES.ADD_CIRCUIT)}>Circuit</Button>
         <br/>
       </>
      );
    } else {
      content = (
       <>
         <Button className="w-100 mb-3" onClick={() => setEditNodeModalOpen(MODES.ADD_CIRCUIT)}>Circuit</Button>
         <br/>
         <Button className="w-100 mb-5" onClick={() => setEditNodeModalOpen(MODES.ADD_EV_CHARGER)}>Ladestation</Button>
         <br/>
       </>
      );
    }

    return (
     <>
       <Modal.Header>
         <Modal.Title>Element hinzufügen </Modal.Title>
         <Icon name="puzzle" color={"#151515"} size={50}/>
       </Modal.Header>
       <Modal.Body>
         {content}
         <Button className="w-100 btn btn-secondary" onClick={() => {
           setEditNodeModalOpen(false);
         }}>Schliessen</Button>
       </Modal.Body>
     </>
    );
  }


  const getVisual = () => {
    switch (editNodeModalOpen) {
      case MODES.ADD_CIRCUIT:
        return <CircuitForm/>
      case MODES.ADD_EV_CHARGER:
        return <EvChargerForm/>
      default:
        return <InitialForm/>
    }
  }

  return (
   <Modal show={editNodeModalOpen} onHide={() => {
     setEditNodeModalOpen(false);
   }}>
     {getVisual()}
   </Modal>
  )
};


export default AddNodeModal;