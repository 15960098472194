import React, {useState} from 'react';

import {Redirect} from 'react-router-dom';

import {Spinner} from 'react-bootstrap';

import {db, useAuthState} from '../Firebase';
import {createUserWithEmailAndPassword, getAuth} from "firebase/auth";

import {doc, setDoc} from 'firebase/firestore';

import {Building, Database, Envelope, Geo, Key, Lock, Person} from "react-bootstrap-icons";


const Signup = (props) => {

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [error, setError] = useState();


  const authState = useAuthState();
  const auth = getAuth();

  const submit = (event) => {
    event.preventDefault();
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // the cloud function can be deleted as we will create the user document here
        // create a new user document with the user's uid]
        console.log(userCredential.user.uid)
        await setDoc(doc(db, "users", userCredential.user.uid), {
          email: email,
          name: name,
          surname: surname,
          address: address,
          companyName: companyName,
          createdAt: new Date(),
        }).then(() => {
          console.log("Document successfully written!");
        }).catch((error) => {
          console.error("Error writing document: ", error);
        });
      })
      .catch((error) => {
        setError(error)
        // ..
      });
    setLoading(false);
  }


  return (
    (authState.user === undefined || loading) ? <Spinner animation="border"/> :
      (authState.user === null ?
        <>
          <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={submit}>
              <h1>Registrieren</h1>
              <p>Bitte geben Sie Ihre Informationen ein, um sich zu registrieren.</p>
              <div className="Auth-form-content">
                <p><Key/> Ihre Zugangsdaten</p>
                <div className="form-group mt-3">
                  <p><Envelope/> E-Mail</p>
                  <input
                    type="email"
                    className="form-control mt-1"
                    placeholder="Geben Sie Ihre E-Mail-Adresse ein"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <p><Lock/> Passwort</p>
                  <input
                    type="password"
                    className="form-control mt-1"
                    placeholder="Geben Sie Ihr Passwort ein"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <p className={"mt-4"}><Database/> Ihre persönlichen Informationen</p>
                <div className="form-group mt-3">
                  <p><Person/> Vorname und Nachname</p>
                  <div className="d-flex">
                    <input
                      type="name"
                      className="form-control mt-1 m-1"
                      placeholder="Ihre Vorname"
                      onChange={(event) => setName(event.target.value)}
                    />
                    <input
                      type="surname"
                      className="form-control mt-1 m-1"
                      placeholder="Ihre Nachname"
                      onChange={(event) => setSurname(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <div style={{justifyContent: "space-between"}} className={"d-flex"}>
                    <p><Geo/> Adresse / Firmenadresse </p>
                    <p> (Optional)</p>
                  </div>
                  <input
                    type="address"
                    className="form-control mt-1"
                    placeholder="Geben Sie Ihre Adresse ein"
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <div style={{justifyContent: "space-between"}} className={"d-flex"}>
                    <p><Building/> Firmenname</p>
                    <p> (Optional)</p>
                  </div>
                  <input
                    type="companyName"
                    className="form-control mt-1"
                    placeholder="Geben Sie Ihre Firmenname ein"
                    onChange={(event) => setCompanyName(event.target.value)}
                  />
                </div>
                {/*if name surname passwort mail not set, make button unclickable*/}

                <div className="d-grid gap-2 mt-4 mb-5">
                  {name && surname && password && email ?
                    <button type="submit" className="btn btn-primary">Registrieren</button> :
                    <button type="submit" className="btn btn-primary" disabled>Registrieren</button>}
                </div>
                <br/>
                {error && <p style={{"color": "red"}}><br/>{error.code} {error.message}</p>}
              </div>
              <p className="kein-konto">Bereits ein Konto haben? <br/> <a href="/">Anmelden</a></p>
            </form>
          </div>
        </>


        :
        <Redirect to="/"/>)
  )
}

export default Signup;