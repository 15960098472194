import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { Row, Col, Button, Card, Table, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import { getDoc, doc } from 'firebase/firestore';
import {db} from '../Firebase';
import { CSVLink } from "react-csv";
import { getAuth } from "firebase/auth";

const csvHeaders = [
  { label: 'Zähler', key: 'name' },
  { label: 'Seriennummern', key: 'serialNumbers' },
  { label: 'Tarif 1 Startwert [kWh]', key: 't1StartValue' },
  { label: 'Tarif 1 Startzeit', key: 't1StartTStamp' },
  { label: 'Tarif 2 Startwert [kWh]', key: 't2StartValue' },
  { label: 'Tarif 2 Startzeit', key: 't2StartTStamp' },
  { label: 'Tarif 1 Endwert [kWh]', key: 't1StopValue' },
  { label: 'Tarif 1 Endzeit', key: 't1StopTStamp' },
  { label: 'Tarif 2 Endwert [kWh]', key: 't2StopValue' },
  { label: 'Tarif 2 Endzeit', key: 't2StopTStamp' },
  { label: 'Tarif 1 Verbrauch in Auswertungsperiode [kWh]', key: 't1Difference' },
  { label: 'Tarif 2 Verbrauch in Auswertungsperiode [kWh]', key: 't2Difference' },
];

const MeterReport = (props) => {
  const { eid } = useParams();

  const [ loading, setLoading ] = useState(false);

  const [ selectedPeriod, setSelectedPeriod ] = useState({start: undefined, end: undefined});
  
  const [ start, setStart ] = useState();
  const [ end, setEnd ] = useState();
  const [ data, setData ] = useState();
  const [ address, setAddress ] = useState("");
  const [ filename, setFilename ] = useState("");

  const generateReport = async() => {

    setLoading(true);

    const edgeSnap = await getDoc(doc(db, "edges", eid));
    if(edgeSnap.exists()) {
      const snapData = edgeSnap.data();
      setAddress(snapData.address);
    }
    
    let start = (new Date(selectedPeriod.start)).toISOString();
    let end = new Date(selectedPeriod.end);
    end.setHours(23,59,59);
    end = end.toISOString();

    setStart(start);
    setEnd(end);
    setFilename(`${eid}_${moment(start).format("DD.MM.YYYY")}-${moment(end).format("DD.MM.YYYY")}.csv`)

    const idTokenResult = await getAuth().currentUser.getIdTokenResult();
    const postData = {
      id: eid,
      start: start,
      stop: end
    };
    const headers = { 
      'Authorization': 'Bearer ' + idTokenResult.token,
    };
    axios.post("https://influxapi.smartchargecontroller.ch/v1/meterReport", postData, {headers}).then(response => {
      if(response.status === 200) {
        let d = [];

        Object.keys(response.data).map(k => {
          d.push({
            name: k,
            serialNumbers: response.data[k].serialNumbers.join(','),
            t1StartValue: response.data[k].t1.start.value.toFixed(3),
            t1StartTStamp: response.data[k].t1.start.tStamp,
            t2StartValue: response.data[k].t2.start.value.toFixed(3),
            t2StartTStamp: response.data[k].t2.start.tStamp,
            t1StopValue: response.data[k].t1.stop.value.toFixed(3),
            t1StopTStamp: response.data[k].t1.stop.tStamp,
            t2StopValue: response.data[k].t2.stop.value.toFixed(3),
            t2StopTStamp: response.data[k].t2.stop.tStamp,
            t1Difference: (response.data[k].t1.stop.value - response.data[k].t1.start.value).toFixed(3),
            t2Difference: (response.data[k].t2.stop.value - response.data[k].t2.start.value).toFixed(3),
          })
        })
        

        setData(d);
        setLoading(false);
      }
      
    }).catch(err => console.log(err));
  }

  return (
    <>
      <h5>Zählerauswertung</h5>
      <Card>
        <Card.Body>
          <Row className="d-flex align-items-end">
            <Col>Startdatum: <DatePicker selected={selectedPeriod.start} onChange={(date) => setSelectedPeriod({...selectedPeriod, start: date})} /></Col>
            <Col>Enddatum: <DatePicker selected={selectedPeriod.end} onChange={(date) => setSelectedPeriod({...selectedPeriod, end: date})}/></Col>
            <Col><Button variant='primary' disabled={!selectedPeriod.start || !selectedPeriod.end || loading} className='mb-0' onClick={generateReport}>Auswertung erstellen</Button></Col>
          </Row>

        </Card.Body>
      </Card>
      <br/>
      {loading ? <Spinner animation="border" size="sm"/> :
      <>
      {data &&
      <>
        <Table>
          <tbody>
            <tr>
              <th>Objekt</th>
              <td>{address}</td>
            </tr>
            <tr>
              <th>Zeitraum der Abrechnung</th>
              <td>{moment(start).format("DD.MM.YYYY")} - {moment(end).format("DD.MM.YYYY")} </td>
            </tr>
          </tbody>
        </Table>
        <CSVLink
          data={data}
          filename={filename}
          className="btn btn-link float-end"
          target="_blank"
          headers={csvHeaders}
        >
          CSV exportieren
        </CSVLink>
        <Table>
          <thead>
            <tr>
              <th rowspan="2">Zähler</th>
              <th colspan="2">Zählerstand Startdatum</th>
              <th colspan="2">Zählerstand Enddatum</th>
              <th colspan="2">Verbrauch in Auswertungsperiode</th>
            </tr>
            <tr>
              <th>Tarif 1 [kWh]</th>
              <th>Tarif 2 [kWh]</th>
              <th>Tarif 1 [kWh]</th>
              <th>Tarif 2 [kWh]</th>
              <th>Tarif 1 [kWh]</th>
              <th>Tarif 2 [kWh]</th>
            </tr>

          </thead>
          <tbody>
            {data.map(d =>
              <tr>
                <td>{d.name}</td>
                <td>{d.t1StartValue}</td>
                <td>{d.t2StartValue}</td>
                <td>{d.t1StopValue}</td>
                <td>{d.t2StopValue}</td>
                <td>{d.t1Difference}</td>
                <td>{d.t2Difference}</td>
              </tr>
            )}

          </tbody>
        </Table>
      </>
      }
      </>}
    </>
  )
}

export default MeterReport;